import React from 'react';
import PropTypes from 'prop-types';
import {
  Lightning as LauncPadIcon,
  Check as PricingListIcon,
  ArrowRight as ButtonIcon,
} from '@phosphor-icons/react';
import Button from '../../elements/Button';

function handleFreshdesk() {
  /* eslint-disable-next-line */
  FreshworksWidget('open');
}

function PricingItem({
  Icon,
  popular,
  title,
  price,
  description,
  servers,
  users,
  devices,
  requests,
}) {
  return (
    <div className={`col col-12 col-md-4 px-2 px-lg-4 mb-4 ${!popular ? 'pt-4' : ''}`} id="pricing">
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-top"
        data-aos-delay={`${!popular ? '400' : '300'}`}
        className={`d-flex h-100 flex-column align-items-center position-relative bg-white radius-large px-8 py-8 mb-8 ${popular ? 'bg-primary clr-white shadow-price-card-popular' : 'bg-white shadow-price-card-base'}`}
      >
        {popular ? <div className="position-absolute top-4 right-4 bg-white clr-dark radius-xlarge px-4 py-2 font-weight-500">Popular</div> : null}
        <div className="d-flex flex-center position-relative p-6 mb-6">
          <div className={`position-absolute top-0 left-0 w-100 h-100 radius-circle ${popular ? 'bg-white opacity-3' : 'bg-primary opacity-1'}`} />
          <Icon size="24" weight="fill" className={`${popular ? 'fill-white' : 'fill-primary'}`} />
        </div>
        <h3 className={`mb-2 spacing-wide text-center ${popular ? 'clr-white' : ''}`}>{title}</h3>
        <div className="d-flex justify-content-center align-items-end mb-8 mt-2">
          <span className={`d-flex mt-0 mr-1 h1 font-weight-700 line-height-1 ${popular ? 'clr-white' : ''}`}>
            $
            {price}
          </span>
          <span className={`d-flex mt-0 h3 font-weight-400 line-height-1 ${popular ? 'clr-white' : ''}`}>/month</span>
        </div>
        <p className="text-center mb-8">{description}</p>
        <ul className="list-unstyled mb-0 p-0 w-100">
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>{servers}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>{users}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>{devices}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>{requests}</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Unlimited Locations</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>VPN Control Panel</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Revenue Cat Integration</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>WHMCS plugin</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Advanced DNS Filtering</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Individual Rest API Solution</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Webhooks events</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <PricingListIcon className={`mr-2 ${popular ? 'fill-white' : 'fill-dark'}`} weight="bold" size="24" />
            <span>Xray, v2Ray, Wireguard, OpenVPN</span>
          </li>
        </ul>
        <Button
          as="a"
          href="#"
          variant={`${popular ? 'light' : 'primary'}`}
          size="lg"
          className="d-flex flex-center w-100 mt-auto"
        >
          <span>Buy Now</span>
          <ButtonIcon className="ml-2" size="24" />
        </Button>
      </div>
    </div>
  );
}

PricingItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  popular: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  servers: PropTypes.string.isRequired,
  users: PropTypes.string.isRequired,
  devices: PropTypes.string.isRequired,
  requests: PropTypes.string.isRequired,
};

function Pricing() {
  return (
    <section className="features py-9 py-lg-20 gradient-white_light" id="pricing">
      <div className="container">
        <div className="row mb-4 mb-lg-12">
          <div className="col col-12 text-center">
            <h1>
              Types of
              {' '}
              <span className="clr-primary">Pricing</span>
            </h1>
            <p className="font-size-lg mb-6 mb-md-16 px-0 px-lg-20">
              Discover the ideal VPN solution for your project with our flexible and
              scalable pricing plans. From startups to large enterprises, we offer
              tailored packages designed to meet your specific needs and
              ensure your digital environment is secure and efficient.
            </p>
          </div>
        </div>
        <div className="row">
          <PricingItem
            Icon={LauncPadIcon}
            popular={false}
            title="Launch Pad"
            price="59"
            description="Ideal for small businesses and startups, offering essential features to kickstart your project"
            servers="Up to 2 Servers"
            users="Up to 10 Users"
            devices="Up to 10 Devices"
            requests="Up to 1 million API requests"
          />
          <PricingItem
            Icon={LauncPadIcon}
            popular
            title="Growth Gear"
            price="399"
            description="Designed for medium-sized projects, providing enhanced capacity and functionality for growing business."
            servers="Up to 100 Servers"
            users="Up to 10,0000 Users"
            devices="Up to 100,000 Devices"
            requests="Up to 10 million API requests"
          />
          <PricingItem
            Icon={LauncPadIcon}
            popular={false}
            title="EnterPrise Edge"
            price="999"
            description="Large projects and enterprises, offering unparalleled scalability, control, and customization for corporate networks."
            servers="Up to 1,000 Servers"
            users="Up to 1,000,000 Users"
            devices="Up to 100,000,000 Devices"
            requests="Up to 100 million API requests"
          />
        </div>
        <div className="row">
          <div className="col col-12 text-center pt-16">
            <h2 className="position-relative z-plus-3 mb-4">
              Custom Connect
            </h2>
            <p className="font-size-lg px-0 px-lg-20 mb-10">
              For those requiring a tailored, customized solution, please
              leave your request, and we&apos;ll find the perfect fit for your needs.
            </p>
            <Button
              as="button"
              variant="primary"
              size="lg"
              className="mx-auto"
              onClick={handleFreshdesk}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
