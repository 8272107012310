import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowRight as MenuIcon } from '@phosphor-icons/react';
import { menuElements } from '../../../_menu';
import Auth from './Auth';

function MobileMenu({ isMobileMenuOpen, closeMobileMenu }) {
  useEffect(() => {
    const handleResize = () => {
      if (isMobileMenuOpen) {
        closeMobileMenu();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileMenuOpen, closeMobileMenu]);

  return (
    <div className={`mmenu position-absolute left-0 bg-light vw-100 vh-100 z-plus-12 px-4 px-sm-8 pt-4 pb-8 ${isMobileMenuOpen ? 'mmenu--opened' : ''}`}>
      <ul className="menu list-unstyled mb-8">
        {menuElements.map((el) => (
          <li
            key={el.title}
            className="d-flex align-items-center"
          >
            <MenuIcon size={32} className="clr-dark" />
            <a
              href={el.href}
              target={el.target ? el.target : '_self'}
              className="mmenu__link px-3 py-4 timing-ease speed-base w-100"
              onClick={closeMobileMenu}
            >
              {el.title}
            </a>
          </li>
        ))}
      </ul>
      <Auth />
    </div>
  );
}

MobileMenu.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  closeMobileMenu: PropTypes.func.isRequired,
};

export default MobileMenu;
