/* eslint-disable */
import React from 'react';
import Button from '../../elements/Button';

function Auth(props) {
  return (
    <div className={props.className}>
      <Button
        as="a"
        href={process.env.REACT_APP_AUTHORIZATION}
        variant="outline-dark"
        className="mr-2"
      >
        Login
      </Button>
      <Button
        as="a"
        href={process.env.REACT_APP_REGISTRATION}
        variant="primary"
      >
        Get Started
      </Button>
    </div>
  );
}

export default Auth;
