import React, { useEffect } from 'react';
/* eslint-disable-next-line no-unused-vars */
import $ from 'jquery';
import 'jquery.easing';

import Header from './views/sections/header/Header';
import Intro from './views/sections/intro/Intro';
import Achievements from './views/sections/achievements/Achievements';
import Benefits from './views/sections/benefits/Benefits';
import Features from './views/sections/features/Features';
import Pricing from './views/sections/pricing/Pricing';
import Cases from './views/sections/cases/Cases';
import Footer from './views/sections/footer/Footer';

function App() {
  useEffect(() => {
    const smoothAnchorClickHandler = (event) => {
      const anchor = event.currentTarget;
      const target = document.querySelector(anchor.getAttribute('href'));
      if (window.matchMedia('(min-width: 1024px)').matches) {
        $('html, body').animate({ scrollTop: target.offsetTop - 90 }, 'slow');
        event.preventDefault();
      } else {
        window.scrollTo(0, target.offsetTop);
        event.preventDefault();
      }
    };

    document.querySelectorAll('.smooth-anchor').forEach((anchor) => {
      anchor.addEventListener('click', smoothAnchorClickHandler);
    });

    // Check for hash in URL on component mount
    const { hash } = window.location;
    if (hash) {
      const target = document.querySelector(hash);
      if (target) {
        const scrollToTarget = () => {
          if (window.matchMedia('(min-width: 1024px)').matches) {
            setTimeout(() => {
              $('html, body').animate({ scrollTop: target.offsetTop - 90 }, 'slow');
            }, 200);
          } else {
            window.scrollTo(0, target.offsetTop);
          }
        };

        // Use requestAnimationFrame to ensure DOM is ready
        requestAnimationFrame(scrollToTarget);
      }
    }
  }, []);

  return (
    <>
      <Header />
      <main className="main">
        <Intro />
        <Achievements />
        <Benefits />
        <Features />
        <Pricing />
        <Cases />
      </main>
      <Footer />
    </>
  );
}

export default App;
