import React from 'react';
import PropTypes from 'prop-types';
import {
  CaretRight,
  ChartBar as ScalabilityIcon,
  ShieldCheckered as SecurityIcon,
  ShuffleAngular as FlexibilityIcon,
  ThumbsUp as ConvenienceIcon,
} from '@phosphor-icons/react';
import IntroStars from '../../../assets/images/intro-stars.svg';
import IntroStar from '../../../assets/images/intro-star.svg';
import IntroPicture from '../../../assets/images/intro-picture.png';
import Button from '../../elements/Button';

function ListItem({ name, Icon }) {
  return (
    <li className="d-flex align-items-center mr-0 mr-xs-8 py-2 w-50 w-xs-auto">
      <Icon className="mr-1 fill-primary" weight="fill" size="24" />
      <span>{name}</span>
    </li>
  );
}

ListItem.propTypes = {
  name: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

function handleFreshdesk() {
  /* eslint-disable-next-line */
  FreshworksWidget('open');
}

function Intro() {
  const items = [
    { name: 'Scalability', icon: ScalabilityIcon },
    { name: 'Flexibility', icon: FlexibilityIcon },
    { name: 'Security', icon: SecurityIcon },
    { name: 'Convenience', icon: ConvenienceIcon },
  ];

  return (
    <section className="intro pt-9 pt-lg-20 pb-6 pb-lg-14">
      <div className="container w-100">
        <div className="row">
          <div className="col col-12 col-md-8 col-lg-6">

            <div className="d-flex align-items-center font-size-sm mb-6">
              <span>Excelent</span>
              <img src={IntroStars} alt="intro-stars" className="ml-3 mr-6 no-select" />
              <img src={IntroStar} alt="intro-star" className="mr-1 no-select" />
              <span className="line-height-1 font-weight-500">Trustpilot</span>
            </div>

            <h1 className="mb-6">
              Start Your Own
              {' '}
              <span data-aos-delay="150" data-aos="flip-up" className="d-inline-block clr-primary">VPN</span>
              {' '}
              or
              {' '}
              <span data-aos-delay="150" data-aos="flip-up" className="d-inline-block clr-primary">Proxy Solutions</span>
              {' '}
              Today
            </h1>

            <p className="mb-10 mb-lg-14 font-size-lg clr-muted">
              Tech stuff is covered, so you can focus on your business growth
            </p>

            <div className="d-flex flex-nowrap mb-10 mb-lg-14">
              <Button
                as="a"
                href={process.env.REACT_APP_REGISTRATION}
                variant="primary"
                size="lg"
                className="mr-4 mr-lg-8"
              >
                Get Started
              </Button>
              <Button
                as="button"
                variant="link"
                size="lg"
                className="px-0"
                onClick={handleFreshdesk}
              >
                Contact Sales
                <CaretRight className="ml-2" weight="bold" />
              </Button>
            </div>
            <ul className="d-flex align-items-center flex-wrap list-unstyled">
              {items.map((item) => (
                <ListItem key={item.name} name={item.name} Icon={item.icon} />
              ))}
            </ul>
          </div>

          <div className="col d-none d-md-block col-md-4 col-lg-6">
            <img
              data-aos-delay="150"
              data-aos="fade-left"
              src={IntroPicture}
              alt="Hexalion control panel and application"
              className="no-select"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
