export const menuElements = [
  {
    title: 'Home',
    href: '/',
    anchor: false,
  },
  {
    title: 'Benefits',
    href: '#benefits',
    anchor: true,
  },
  {
    title: 'Pricing',
    href: '#pricing',
    anchor: true,
  },
  {
    title: 'Features',
    href: '#features',
    anchor: true,
  },
  {
    title: 'Use Cases',
    href: '#cases',
    anchor: true,
  },
  {
    title: 'API Integration',
    href: process.env.REACT_APP_API,
    anchor: false,
    target: '_blank',
  },
];
