import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import logoImage from '../../assets/images/logo.svg';

function Logo({ className }) { // Destructure className from props
  return (
    <a href="/" className={`d-flex align-items-center ${className}`}>
      <img src={logoImage} alt="logo" className="logo no-select" width="200" />
    </a>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

// Add defaultProps declaration
Logo.defaultProps = {
  className: '', // Set className to an empty string as default
};

export default Logo;
