import React from 'react';
import PropTypes from 'prop-types';
import Hamburger from 'react-hamburgers';

function MenuSwitcher({ toggleMobileMenu, isMobileMenuOpen }) {
  return (
    <div className="d-block d-lg-none">
      <Hamburger
        active={isMobileMenuOpen}
        type="spin"
        onClick={toggleMobileMenu}
      />
    </div>
  );
}

MenuSwitcher.propTypes = {
  toggleMobileMenu: PropTypes.func.isRequired,
  isMobileMenuOpen: PropTypes.bool.isRequired,
};

export default MenuSwitcher;
