import React from 'react';
import PropTypes from 'prop-types';

import MobileApp from '../../../assets/icons/case-mobile-app.svg';
import CorporateVpn from '../../../assets/icons/case-corporate-vpn.svg';
import DataProtection from '../../../assets/icons/case-data-protection.svg';
import GeoRestrictions from '../../../assets/icons/case-geo-restrictions.svg';
import VpnProxy from '../../../assets/icons/case-vpn-proxy.svg';
import RemoteAccess from '../../../assets/icons/case-remote-access.svg';

function Case({ name, image }) {
  return (
    <div className="col col-12 col-xs-6 col-md-4 col-lg-2 d-flex flex-column align-items-center mb-12">
      <div className="d-flex flex-center position-relative p-4 mb-6">
        <div className="bg-primary position-absolute top-0 left-0 opacity-1 w-100 h-100 radius-base" />
        <img src={image} alt={name} className="icon-size-3xl no-select" />
      </div>
      <div className="font-size-lg font-weight-500 clr-dark m-0 text-center px-5">{name}</div>
    </div>
  );
}

Case.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.elementType.isRequired,
};

function Cases() {
  const caseItems = [
    { name: 'Mobile App Developement', image: MobileApp },
    { name: 'Corporate VPN Networks', image: CorporateVpn },
    { name: 'Corporate Data Protection', image: DataProtection },
    { name: 'Bypassing Geo Restrictions', image: GeoRestrictions },
    { name: 'VPN & PROXY Solution for Startup', image: VpnProxy },
    { name: 'Remote Employee Access', image: RemoteAccess },
  ];

  return (
    <section className="cases pt-9 pt-lg-20 pb-12" id="cases">
      <div className="container">
        <div className="row">
          <div className="col col-12 text-center">
            <h1>
              Our Use
              {' '}
              <span className="clr-primary">Cases</span>
            </h1>
            <p className="font-size-lg mb-8 mb-md-14 px-0 px-lg-20">
              How companies use our PaaS Platform
            </p>
          </div>
        </div>
        <div className="row">
          {caseItems.map((item) => (
            <Case
              key={item.name}
              name={item.name}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Cases;
