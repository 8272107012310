import React from 'react';
import {
  MapPin as AddressIcon,
  Envelope as EmailIcon,
} from '@phosphor-icons/react';
import Logo from '../../elements/Logo';
import { menuElements } from '../../../_menu';

function Footer() {
  return (
    <footer className="footer pt-12 pt-md-20 pb-8 pb-md-12 bg-light">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-4 pr-20 pb-10 pb-md-0">
            <Logo className="mb-4" />
            <p className="mb-8">
              Your complete VPN solution. Build any VPN service on a secure, fast,
              and ready-for-deployment platform tailored for all your network needs.
            </p>
            <ul className="list-unstyled m-0 p-0">
              <li className="d-flex align-items-start clr-dark mb-3">
                <AddressIcon className="mr-2 fill-primary icon-size-xl" weight="fill" size="24" />
                <span>
                  Suite 401, Agias Zonis Centre, Agias Zonis Street 12, Limassol, 3027, Cyprus
                </span>
              </li>
              <li className="d-flex align-items-start clr-dark">
                <EmailIcon className="mr-2 fill-primary" weight="fill" size="24" />
                <span>support@hexallion.com</span>
              </li>
            </ul>
          </div>
          <div className="col col-12 col-md-8">
            <div className="row">
              <div className="col col-12 col-xs-6 col-md-3 pb-8 pb-md-0">
                <h4 className="mt-0 mb-6">Quick Links</h4>
                <ul className="list-unstyled">
                  {menuElements.map((el) => (
                    <li key={el.title} className="mb-3">
                      <a
                        href={el.href}
                        className="footer__link smooth-anchor"
                      >
                        {el.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col col-12 col-xs-6 col-md-3 pb-8 pb-md-0">
                <h4 className="mt-0 mb-6">General</h4>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_PRIVACY} className="footer__link" rel="noreferrer">Privacy Policy</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_TERMS} className="footer__link" rel="noreferrer">Terms of Use</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_GDPR} className="footer__link" rel="noreferrer">GDPR</a>
                  </li>
                </ul>
              </div>
              <div className="col col-12 col-xs-6 col-md-3 pb-8 pb-md-0">
                <h4 className="mt-0 mb-6">Resources</h4>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_API} className="footer__link" rel="noreferrer">API Integration</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_OPEN_VPN} className="footer__link" rel="noreferrer">Open VPN</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_WIREGUARD} className="footer__link" rel="noreferrer">Wireguard</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_XRAY} className="footer__link" rel="noreferrer">Xray</a>
                  </li>
                  <li className="mb-3">
                    <a target="_blank" href={process.env.REACT_APP_V2FLY} className="footer__link" rel="noreferrer">V2Fly</a>
                  </li>
                </ul>
              </div>
              <div className="col col-12 col-xs-6 col-md-3 pb-8 pb-md-0">
                <h4 className="mt-0 mb-6">Locations</h4>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <a href="/" className="footer__link">All Locations</a>
                  </li>
                  <li className="mb-3">
                    <a href="/" className="footer__link">Unite States</a>
                  </li>
                  <li className="mb-3">
                    <a href="/" className="footer__link">United Kingdom</a>
                  </li>
                  <li className="mb-3">
                    <a href="/" className="footer__link">Germany</a>
                  </li>
                  <li className="mb-3">
                    <a href="/" className="footer__link">China</a>
                  </li>
                  <li className="mb-3">
                    <a href="/" className="footer__link">India</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col col-12 pt-0 pt-md-12">
            Copyright 2024 ©
            {' '}
            <span className="clr-primary font-weight-500">Hexallion</span>
            {' '}
            All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
