import React from 'react';
import PropTypes from 'prop-types';
import BenefitsArrow from '../../../assets/images/benefits-arrow.svg';
import Deployment from '../../../assets/icons/benefits-deployment.svg';
import Dns from '../../../assets/icons/benefits-dns.svg';
import Flexibility from '../../../assets/icons/benefits-flexibility.svg';
import Protocols from '../../../assets/icons/benefits-protocols.svg';
import Solution from '../../../assets/icons/benefits-solution.svg';
import Support from '../../../assets/icons/benefits-support.svg';

function BenefitsItem({ title, text, icon }) {
  return (
    <div
      data-aos="fade-in"
      data-aos-anchor-placement="top-center"
      className="col col-12 col-xs-6 col-lg-4 px-4 mb-8"
    >
      <div className="benefits__card position-relative col d-flex flex-column align-items-center py-6 px-10 py-lg-20 px-lg-13 radius-large h-100">
        <div className="d-flex flex-center position-relative p-4 mb-6">
          <div className="bg-primary position-absolute top-0 left-0 opacity-1 w-100 h-100 radius-base" />
          <img src={icon} alt={title} className="icon-size-3xl no-select" />
        </div>
        <h3 className="text-center">{title}</h3>
        <p className="text-center mb-0">{text}</p>
      </div>
    </div>
  );
}

BenefitsItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
};

function Benefits() {
  const items = [
    {
      title: 'Comprehensive Solution',
      text: 'One platform for all your VPN needs, from mobile app development to corporate network construction.',
      icon: Solution,
    },
    {
      title: 'Infrastructure Flexibility',
      text: 'Easily integrate with any physical or cloud infrastructure for seamless network deployment.',
      icon: Flexibility,
    },
    {
      title: 'Advanced DNS Filtering',
      text: 'Comprehensive protection against DNS-based threats such as ransomware and phishing attacks.',
      icon: Dns,
    },
    {
      title: 'Industry-Leading Tunneling Protocols',
      text: 'Powerful VPN protocols including Xray, v2Ray, Wireguard, and OpenVPN, capable of swiftly bypassing DPI firewalls for high-speed data transfer.',
      icon: Protocols,
    },
    {
      title: 'Ready-for-Deployment Solution',
      text: 'Clients benefit from reduced development costs and obtain a solution that can be deployed instantly, simplifying the transition to a secure and efficient network environment.',
      icon: Deployment,
    },
    {
      title: 'Reliable 24/7 Support',
      text: 'Count on our technical support team to assist whenever your clients encounter issues.',
      icon: Support,
    },
  ];

  return (
    <section className="benefits gradient-white_light py-9 py-lg-20" id="benefits">
      <div className="container">
        <div className="row">
          <div className="col col-12 text-center">
            <div className="position-relative">
              <h1 className="position-relative z-plus-3">
                Explore our Amazing
                {' '}
                <span className="clr-primary">Benefits</span>
              </h1>
              <p className="font-size-lg position-relative z-plus-3 mb-16 px-0 px-lg-20">
                Tech stuff is covered, so you can focus on your business growth
              </p>
              <img
                src={BenefitsArrow}
                alt="decorative-arrow"
                className="position-absolute bottom-0 left-10 left-xl-15 z-plus-2 d-none d-lg-block no-select"
              />
            </div>
          </div>
        </div>
        <div className="row benefits__cards">
          {items.map((item) => (
            <BenefitsItem
              key={item.title}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
