import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import SaasWorthy from '../../../assets/images/achievement-saasworthy.png';
import Capterra from '../../../assets/images/achievement-capterra.png';
import Frontrunners from '../../../assets/images/achievement-frontrunners.png';
import Getapp from '../../../assets/images/achievement-getapp.png';
import Goodfirms from '../../../assets/images/achievement-goodfirms.png';
import HappiestUsers from '../../../assets/images/achievement-happiestusers.png';
import HighPerformer from '../../../assets/images/achievement-highperformer.png';

function Achievements() {
  const sliderItems = [
    { name: 'SaaS Worthy 2021', image: SaasWorthy },
    { name: 'Capterra Shortlist 2022', image: Capterra },
    { name: 'Frontrunners 2022', image: Frontrunners },
    { name: 'Getapp Leader 2023', image: Getapp },
    { name: 'Goodfirms', image: Goodfirms },
    { name: 'Happiest Users 2024', image: HappiestUsers },
    { name: 'High Performer Spring 2023', image: HighPerformer },
  ];

  return (
    <section className="achievements bg-light" id="achievements">
      <div className="container px-0">
        <Swiper
          modules={[Navigation]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          className="py-9 py-lg-14"
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 5,
            },
            1600: {
              slidesPerView: 7,
            },
          }}
        >
          {sliderItems.map((item, index) => (
            <SwiperSlide key={item.name} name={item.name}>
              <img
                src={item.image}
                alt={item.name}
                data-aos-delay={index * 50}
                data-aos="fade-up"
                className="d-block no-select mx-auto"
                width="145"
                height="112"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default Achievements;
