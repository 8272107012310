/* eslint-disable */
import classNames from 'classnames';

function Button({
  as: Component = 'button', variant, className, size, ...restProps
}) {
  const commonProps = {
    className: classNames(
      'button',
      {
        [`button--${variant || 'primary'}`]: true,
        [`button--${size || 'md'}`]: true,
      },
      className,
    ),
    ...restProps,
  };

  return <Component {...commonProps} />;
}

export default Button;
