import React from 'react';
import { menuElements } from '../../../_menu';

function Menu() {
  return (
    <ul className="menu d-none d-lg-flex align-items-center list-unstyled no-select m-0">
      {menuElements.map((el) => (
        <li key={el.title}>
          <a
            href={el.href}
            target={el.target ? el.target : '_self'}
            className={`menu__link px-3 py-4 timing-ease speed-base ${el.anchor ? 'smooth-anchor' : ''}`}
          >
            {el.title}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Menu;
