import React, { useState } from 'react';
import Logo from '../../elements/Logo';
import Menu from './Menu';
import Auth from './Auth';
import MenuSwitcher from './MenuSwitcher';
import MobileMenu from './MobileMenu';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header d-flex align-items-center shadow-header position-fixed top-0 left-0 w-100 bg-white z-plus-12">
      <div className="container w-100">
        <div className="row">
          <div className="d-flex align-items-center justify-content-between col col-12">
            <Logo />
            <Menu />
            <MenuSwitcher
              toggleMobileMenu={toggleMobileMenu}
              isMobileMenuOpen={isMobileMenuOpen}
            />
            <MobileMenu
              isMobileMenuOpen={isMobileMenuOpen}
              closeMobileMenu={closeMobileMenu}
            />
            <Auth className="d-none d-lg-flex" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
